// import request from '../../../../utils/request';
import BasePage from '../../../../components/page_base';

export default {
  name: 'buttonManage',
  extends: BasePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  methods: {
    setColumn(col) {
      const rowData = col;
      if (rowData.field === 'iconStyle') {
        rowData.type = 'html';
        rowData.formatter = ({ row }) => `<i class='${row.iconStyle}'>`;
      }
      return rowData;
    },
    setFormField(field) {
      if (this.modalConfig.config.code === 'edit') {
        this.modalConfig.title = '编辑';
      } else {
        this.modalConfig.title = '新增';
      }
      const thisField = field;
      if (thisField.field === 'iconCode') {
        thisField.restful = '/mdm/mdmicon/listCondition';
        thisField.restfulParams = {
          iconType: '1',
        };
        thisField.optionsKey = {
          label: 'iconName',
          value: 'iconCode',
        };
      }
      return { ...thisField };
    },
  },
};
